
.mama-maria{

    margin: 100px 0px;
    .subtitle{
        margin-bottom: 10px;
    }
    h1{
        font-size: var(--title-font-size);
    }
    &__main-text{
        margin-left: var(--container-margin);
        width: var(--width);
    }
    &__info{
        margin: 60px 0px 60px 0px;
        width: var(--width);
        margin-left: var(--container-margin);
        p{
            white-space: pre-wrap;
            font-size: 18px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 5;
        }
        .active-intro{
            display: block;
        }
        button{
            background: none;
            border: 1px solid black;
            padding: 5px 10px;
            font-size: 16px;
            margin-top: 10px;
            &:hover{
                background: black;
                color: white;
            }
        }
    }
    .main-text{
        font-size: 18px;
        max-width: var(--width);
        white-space: pre-wrap; /* Сохранение пробелов и переносы строк */
        word-wrap: break-word; /* Перенос слов */
    }
    .pagination-container{
        width: var(--width);
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
        &__button{

            padding: 5px;
            background: rgba(255, 255, 255, 0.5);
            border: 1px solid rgb(14, 21, 41);
            font-size: 16px;
            &:disabled{
                color: black;
                background-color: rgb(255, 255, 255);
            }
        }
        &__container{
            
            display: flex;
            margin-top: 30px;
            
        }
    }
    .poem{
        max-width: 500px;
        margin-left: 0px;
        font-family: 'Times New Roman', Times, serif;
        font-size: 18px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}

@media screen and (max-width: 768px) {
    .mama-maria{
        margin: 20px var(--container-margin);
        background-size: 100vw;
        &__info{
            margin: 60px 0px 60px 0px;
            padding: 0px 20px;
        }
        &__main-text{
            padding: 0px 20px;
        }
    }
  
    .pagination-container{
        gap: 10px;
        width: calc(var(--width) - 40px) !important;
        &__button{
            
        }
    }
}