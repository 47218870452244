

.poem{
    background-size: 30vw;
    // background-attachment:scroll;
    // background-position:bottom;
    width: 100vw;
    z-index: 2;
    padding-bottom: 50px;
    &__top-block{
        width: 100vw;
        height: 250px;
        // background: url('../images/flag.jpg');
        // background-position: center;
        // background-size: cover;
        top: 0;
        position: relative;
        h1{
            width: max-content;
            font-size: 50px;
            position: absolute;
            margin-top: 160px;
            // text-shadow: 0px 0px 7px rgb(0, 0, 0);
            margin-left: 50%;
            transform: translateX(-50%);
            font-family: "Marck Script", cursive;
            color: rgb(0, 0, 0);
        }
    }
    &__content{
        z-index: 100 !important;
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        background-color: rgba(0, 0, 0, 0.1);
        width: fit-content;
        padding: 30px;
      
        img{
            width: 250px;
            height: 250px;
            
        }
        pre{
            font-size: 22px;
            width: fit-content;
        }
        transform: translateX(-50%);
        margin-left: 50%;
        .jj{
            margin-left: 80px;
            // display: none;
            background-color: rgb(255, 255, 255);
            height: max-content;
            padding: 7px;
            border-radius: 5px;
            p{
                margin: 5px 0px;
            }
        }
    }

}

@media screen and (max-width: 768px) {
    .poem{
        background-size: 100vw;
        background-repeat: repeat;
        padding-top: 90px;
        &__top-block{
            width: 100vw;
            height: max-content;
           
            top: 0;
            position: relative;
            padding: 0px 20px;
          
            h1{
                text-align: center;
                width:fit-content;
                font-size: 36px;
                position: relative;
                margin-top: 0px;
                text-shadow: 0px 0px 0px rgb(0, 0, 0);
                margin-left: 0%;
                transform: translateX(0%);
                font-family: "Marck Script", cursive;
                color: rgb(0, 0, 0);
            }
        }
        &__content{
            width: calc(var(--width) - 80px);
            
            margin-top: 20px;
            transform: translateX(-50%);
            margin-left: 50%;
            padding: 15px;
            display: block;
            background-color: rgba(255, 255, 255, 0.6);
            pre{
                white-space: pre-wrap;   /* Позволяет переносить строки */
            word-wrap: break-word;   /* Позволяет переносить слова */
            overflow-wrap: break-word; /* Альтернативное свойство для переноса слов */
                font-size: 20px;
            }
        }
        .jj{
            margin-left: 0px;
            background-color: rgb(255, 255, 255, 0);
            height: max-content;
            padding: 0px;
            border-radius: 5px;
            margin-bottom: 30px;
            p{
                margin: 5px 0px;
            }
        }
    }
}

