@import url("https://fonts.googleapis.com/css2?family=Marck+Script&display=swap");

body{
    overflow-x: hidden;
    background-image: url('./images/background.png');
    background-size: 25vw;
}

*{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
        -webkit-tap-highlight-color: transparent; /* Для WebKit (Chrome, Safari, Opera) */
        -webkit-touch-callout: none; /* Отключение контекстного меню при долгом нажатии */
}

button{
    transition: 0.3s ease;
    cursor: pointer;
}

.title{
    width: 100%;
    text-align: center;
    margin: 30px 0px;
    font-size: var(--title-font-size);
}

.underlined{
    position: relative;
    &::after{
        content: '';
        width: 50px;
        background-color: gray;
        height: 2px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 5px;
    }
    margin-bottom: 40px;
}

.marck {
    font-family: "Marck Script", cursive;
}

:root {
    --menu: 400px;
    --width: 1040px;
    --font-size: 18px;
    --title-font-size: 36px;
    --container-margin: calc((100vw - var(--width)) / 2)
}
  

@media screen and (max-width: 1040px) {
    :root {
        --font-size: 17px;
        --width: calc(100vw - 40px);
        --title-font-size: 26px;
        --container-margin: 20px;
    }
}

@media screen and (max-width: 769px) {
    body{
        --width: calc(100vw);
        background-size: 100vw;
        --container-margin: 0px;
    }
}


.footer{
    .fb-comments{
        width: var(--width);
        // background-color: rgba(255, 255, 255, 0.5);
        background-color: rgba(0, 0, 0, 0.1);
        padding: 10px;
    }
    width: var(--width);
    margin: 0px calc((100vw - var(--width)) / 2);
    margin-top: 60px;
    &__content{
        display: flex;
        justify-content: center;
    }
    h1{
        font-size: var(--title-font-size);
    }
    h3{
        font-size: var(--font-size);
        margin: 5px 0px 10px 0px;
    }
    
    p{
        margin: 0px 10px;
        font-size: 20px;
        color: grey;
    }
}

::selection {
    background-color: #3367D1; /* Желтый цвет выделения */
    color: #ffffff; /* Черный цвет текста в выделенной области */
}

@media screen and (max-width: 769px) {
    .footer{
        padding: 0px 20px;
        .fb-comments{
            width: calc(var(--width) - 40px);
            background-color: rgba(255, 255, 255, 0.6);
            text-align: center;
            padding: 10px 0px;
        }
    }
}

body::-webkit-scrollbar {
    width: 10px; 
    background: white;                    /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: rgb(159, 159, 159);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);  /* creates padding around scroll thumb */
    &:hover{
        background-color: rgb(122, 122, 122); 
    }
  }