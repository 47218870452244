

.biography{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 100px calc((100vw - var(--width)) / 2) 40px calc((100vw - var(--width)) / 2);
    width: 100vw;
    background-size: 30vw;
    &__content{
        width: calc(var(--width) - 400px);
        .contents{
            padding: 10px;
            width: 250px;
            height: max-content;
            background-color: rgb(255, 255, 255, 0.7);
            border: 1px solid rgb(212, 212, 212);
            h4{
                cursor: pointer;
                font-size: 16px;
                font-weight: 300;
                color: rgb(0, 33, 219);
                &:hover{
                    text-decoration: underline;
                }
            }
            h3{
                font-size: 18px;
            }
        }
        h2{
            margin: 30px 0px 20px 0px;
        }
        p{
            font-size: var(--font-size);
            white-space: pre-wrap;
        }
    }


    &__absolute-block{
       
        width: 310px;
        padding: 5px 5px 0px 5px;
        background-color: rgb(255, 255, 255, 0.7);
        border: 1px solid rgb(212, 212, 212);
        height: max-content;
        img{
            border-radius: 5px;
            width: 300px;
            height: 180px;
            object-fit: cover;
        }
        ul{
            li{
                display: flex;
                margin-left: 0px;
                list-style: none;
                margin: 5px 0px;
            }
        }
    }

    .table{
        &__title{
            padding: 3px 5px;
            width: 100px;
            background-color: rgba(86, 86, 86, 0.2);
        }
        &__subtitle{
            display: flex;
            align-items: center;
            width: calc(100% - 100px);
            font-size: 14px;
            margin-left: 5px;
        }
    }
}


@media screen and (max-width: 768px) {
    .biography{
        position: relative;
        display: block;
        justify-content: space-between;
        padding: 80px 20px 20px 20px;
        width: calc( var(--width)) ;
        margin-left: 0%;
        transform: translateX(0%);
        background-size: 100vw;
        &__content{
            width: calc( var(--width) - 40px) ;
            
            .contents{
                margin: 0;
                margin-bottom: 20px;
            }
        }
        &__absolute-block{
            // width: calc(var(--width) - 40px);
            // img{
            //     width: calc(var(--width) - 50px);
            //     height: 250px;
            // }
            position: relative;
            
        }
        h2{
            margin: 20px 0px 10px 0px;
        }
    }
}