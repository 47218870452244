

.menu-container{
    width: 100vw;
    .menu{
    
        &__top-bar{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        z-index: 1000;
        position: fixed;
        padding: 10px 20px;
        background: rgba(255, 255, 255, 0.7);
        
        backdrop-filter: blur(15px);
        // background-image: url('../images/Без\ названия252_20240415121621.png');
        // background-size: 30vw;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
        transition: 0.3s ease;
        height: 60px;
        h1{
          
            line-height: 1.1;
            font-size: 24px;
            font-family: "Marck Script", cursive;
        }
        h6{
           
            font-size: 11px;
            // font-family: "Marck Script", cursive;
            font-style:italic;
            color: rgb(92, 92, 92);
        }
        ul{
            display: flex;
            li{
                padding: 0px 15px;
                list-style: none;
                font-size: 18px;
                a{
                    font-family: "Marck Script", cursive;
                    text-decoration: none;
                    color: gray;
                    &.active{
                        color: black;
                        text-decoration: underline;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.menu.open{
    height: max-content;   
}

@media screen and (max-width: 769px) {
    .menu-container{
    .menu{
        background: rgba(255, 255, 255, 1);
        button{
            background: none;
            display: flex;
            border: none;
            img{
                height: 20px;
                width: 20px;
            }
        }

        .mobile-menu-list{
            height: max-content;
            display: flex;
            justify-content: end;
            ul{
                text-align: end;
                display: block;
                li{
                    padding: 5px 0px;
                }
            }
        }
        
    }
    }
}
