.audio_player{
    z-index: 100;
    padding: 10px 10px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: max-content;
    background: linear-gradient(to right, rgb(193, 232, 255), rgb(255, 255, 205));
    border-radius: 10px;
    .player_content{
        
        input{
            
            width: 100%;
            height: 7px;
            
            border-radius: 5px; 
            &::-moz-range-thumb {
                    width: 15px;
                    height: 10px;
                    background-color: #008cff;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;
                  }
          
            
        }
        p{
            margin: 0px 10px;
        }
        align-items: center;
        display: flex;
        width: 100%;
        
        .player_button{
            background: rgba(0, 0, 0, 0.3);
            margin-right: 5px;
            border-radius: 5px;
            position: relative;
            width: 25px;
            height: 25px;
            
            cursor: pointer;
           
            img{
             
                position: absolute;
                object-fit: contain;
                width: 100%;
                padding: 4px;
                height: 100%;
               
            }
        }
    }
    .rewind{
        margin-top: 5px;
        input{
            width: 20px;
            background-color: #2e353d33;
            border-radius: 3px;
            border: none;
            padding: 3px;
            outline: none;
        }
        button{
            margin-left: 5px;
            border: none;
            background: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .time-management-block{
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 5px;
        .format-time{
            background-color: #2e353d33;
            margin: 0px;
            line-height: 1;
            width: max-content;
            padding: 3px;
            border-radius: 3px;
            margin-right: 10px;
        }
        &:hover{
            .format-time{
                background: rgba(0, 0, 0, 0.3);
            }
        }
    }

}

.send_audio_player{
    height: 50px; 
    background-color: aqua;
    
    background: rgba(0, 0, 0, 1);
    .send_player_content{
        
        input{
            width: 100%;
            height: 7px;
            background-color: #2e353d;
            border-radius: 5px;
            &::-moz-range-thumb {
                    width: 15px;
                    height: 10px;
                    background-color: #008cff;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;
                  }
            &::-ms-fill-lower {
                    background: #777;
                    border-radius: 10px;
                }
            &:focus::-ms-fill-lower {
                    background: #888;
                }
            
        }
        
        align-items: center;
        display: flex;
        width: 100%;
        .send_player_button{
            background: rgba(0, 0, 0, 0.3);
            margin-right: 5px;
            border-radius: 5px;
            position: relative;
            width: 36px;
            height: 36px;
            padding: 7px;
           
            img{
                object-fit: contain;
                width: 100%;
               
                height: 100%;
                border-radius: 0px;
            }
        }
    }
    p{
    
        margin: 0px;
     
        line-height: 1;
    }
}

.spot{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    height: 25px;
    cursor: pointer;
}
.logo{
    
    bottom: 0;
    margin: 0px;
    position: relative;
    width: calc(200px / 52);
    
    max-height: 25px;
    min-height: 5px;
    border-radius: 3px;
   
}



@media screen and (max-width: 899px) {
  
    // .spot{
       
    //     max-width: 100px;
    // }
   
  
}
















// .audio-player{
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     position: fixed;
//     bottom: 15px;
//     right: 15px;
//     width: 260px;
//     border-radius: 50px;
//     padding: 10px 15px;
    
//     box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
//     img{
//         width: 20px;
//     }
// }
// .volume-container{
//     width: 20px;
//     height: 20px;
//     background: url('../images/volume.png');
//     background-size: cover;
//     writing-mode: bt-lr; 
//     position: relative;
// }
// #volume{
//     writing-mode: bt-lr; /* Устанавливаем вертикальный режим написания */
//     transform: rotate(270deg); /* Поворачиваем элемент на 270 градусов по часовой стрелке */
//     width: 100px;
//     position: absolute;
//     bottom: 70px;
//     left: -40px;
// }

// /* Убрать стандартные стили браузера */
// #progress {
//     -webkit-appearance: none;
//     -moz-apperance: none;
//     appearance: none;
//     width: 175px; /* Ширина ползунка */
//     height: 20px; /* Высота ползунка */
//     border-radius: 5px; /* Радиус скругления углов */
//     outline: none; /* Убираем обводку */
//     padding: 0; /* Убираем отступы */
//     margin: 0; /* Убираем отступы */
// }

// /* Цвет заполненной области */
// #progress::-webkit-slider-runnable-track {
//     background: #407d9b; /* Зеленый */
//     height: 10px;
//     border-radius: 3px;
// }

// /* Цвет ползунка */
// #progress::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     width: 15px; /* Ширина ползунка */
//     height: 15px; /* Высота ползунка */
//     background: #fff; /* Цвет фона */
//     border: 2px solid #2f5a70; /* Обводка */
//     border-radius: 5px; /* Круглый ползунок */
//     cursor: pointer; /* Изменяем курсор при наведении */
//     margin-top: -2.5px; /* Центрирование по вертикали */
// }

// /* Для Firefox */
// #progress::-moz-range-track {
//     background: #407d9b; /* Зеленый */
//     height: 10px;
//     border-radius: 3px;
// }

// #progress::-moz-range-thumb {
//     -webkit-appearance: none;
//     width: 15px; /* Ширина ползунка */
//     height: 15px; /* Высота ползунка */
//     background: #fff; /* Цвет фона */
//     border: 2px solid #2f5a70; /* Обводка */
//     border-radius: 5px; /* Круглый ползунок */
//     cursor: pointer; /* Изменяем курсор при наведении */
//     margin-top: -2.5px; /* Центрирование по вертикали */
// }

// /* Для Edge и IE */
// #progress::-ms-track {
//     background: #4CAF50;
// }

// #progress::-ms-thumb {
//     width: 20px;
//     height: 20px;
//     background: #fff;
//     border: 2px solid #4CAF50;
//     border-radius: 50%;
//     cursor: pointer;
// }