
.items-container{
    width: var(--width);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-left: 50%;
    transform: translateX(-50%);
}




.item{
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    width: 320px;
    margin: 0px 0px;
    background-color: white;
    transition: 0.2s ease;
    &__content{
        box-shadow: 5px -3px 15px -5px rgba(0, 0, 0, 0.06);
        border-right: 1px solid rgba(128, 128, 128, 0.1);
        font-size: 24px;
        top: 0;
        background-color: rgb(255, 255, 255);
        width: 103%;
        // padding: 5px 30px 0px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 70px;
        height: max-content;
        text-align: center;
        line-height: 1;
        z-index: 5;
        position: relative;
        font-family: "Marck Script", cursive;
    }
    img{
        width: 100%;
        height: 200px;
        object-fit: cover;
        display: flex;
    }
    
    .item__shadow{
        width: 100%;
        height: 10px;
      
        background: linear-gradient(to right, rgb(234, 234, 234), rgb(204, 204, 204)) ;
        position: relative;
        z-index: 2;
        transform: rotate(-1deg);
        &::before{
            content: '';
            background-color: rgb(255, 255, 255);
            z-index: 1;
            height: 100%;
            position: absolute;
            transform: rotate(-1deg);
            width: 102.5%;
            top: -3px; 
            left: 1px;       
        }
        &::after{
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            border: 7px solid transparent;
            border-left-color: transparent;
            border-bottom-color: #6e6e6e;
            right: -6px;
            z-index: 0;
            bottom: 2px;
            transform: rotate(-45deg);
        }
    }

    &:hover{
        cursor: pointer;
        transform: scale(1.02);
        button{
            color: black;
            // text-decoration: underline;
        }
    }

    button{
        background: none;
        margin: 15px 0px 20px 75%;
        font-size: 16px;
        border: none;
        color: grey;
        &:hover{
            color: black;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 768px) {
    .items-container{
        width: var(--width);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .item{
        box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.1);
        width: calc((100vw - 45px) / 2);
        &__content{
            text-align: center;
            font-size: 21px;
        }
        img{
            width: 100%;
            height: 150px;
            object-fit: cover;
            display: flex;
        }
        .item__shadow{
            &::after{
                border: 5px solid transparent;
                border-left-color: transparent;
                border-bottom-color: #6e6e6e;
                
                right: -5px;
                
            }
        }
        button{
            font-size: 14px;
            margin: 10px 10px 15px 70%;
        }
    }

}