
.main{
    
    // background-image: url('../images/background.png');
    h3{
        font-size: var(--font-size);
    }
}

.first-section{
    width: var(--width);
    padding-top: 140px;
    margin-left: var(--container-margin);
    height: 400px;
    position: relative;
    display: flex;
    align-items: center;
    .background{
        z-index: 1;
        width: 100%;
        height: 220px;
        // background: url('../images/147779.jpg');
        // background-position: bottom;
        background-size: 100vw;
        // background-repeat: no-repeat;
     
        position: absolute;
        background-color: rgba(0, 0, 0, 0.1);
        // background-color: rgba(255, 255, 255, 0.7);
        // box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
        // backdrop-filter: invert(100%);
        // background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
        // background-image: url('../images/close-up-fabric-texture.jpg');
        
        // filter:contrast(150%);
        // opacity: 0.8;
      
    }
    &__info{
        width: 100vw;
        margin: 0px 35px;
        // margin: 0px calc((100vw - var(--width)) / 2);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;
        position: relative;
        h1{
            // display: none;
            margin: 10px 0px;
            // color: white;
            font-size: 34px;
            font-family: "Marck Script", cursive;
            // text-shadow: 0px 0px 20px black;
        }
    }
    &__info-left-block{
        h1{
            margin: 0;
        }
        button{
            margin-top: 10px;
            border: 2px solid rgb(0, 0, 0);
            padding: 10px 15px;
            background: none;
            color: rgb(0, 0, 0);
            font-size: 18px;
            &:hover{
                background: rgb(0, 0, 0);
                color: rgb(255, 255, 255);
            }
        }
    }
    &__info-right-block{
        width: 250px;
        display: flex;
        justify-content: center;
       
        
        img{
            width: 250px;
         
            // object-fit: contain;
        }
    }
    // &::before{
    //     content: '';
    //     width: 100vw;
    //     height: 10vh;
    //     background-color: white;
    //     transform: rotate(-1deg);
    //     position: absolute;
    //     bottom: -5vh;
    //     left: 0;
    //     z-index: 1;
    // }
}

.second-section{
    
    width: var(--width);
    margin-left: 50%;
    transform: translate(-50%);
    padding-top: 0vh;
    h1{
        text-align: start;
    }
    &__content{
        display: flex;
    }
    &__right-block{
        width: 250px;
        display: none;
        align-items: start;
        justify-content: end;
        img{
            object-fit: cover;
            width: 200px;
            height: 300px;
            border-radius: 5px;
        }
    }
    &__left-block{
        width: calc(100%);
        h1{
            font-family: "Marck Script", cursive;
            font-size: 36px;
            margin: 15px 0px;
        }
        p{
           
            font-size: 18px;
            
            white-space: pre-wrap;
        }
        // button{
        //     border: 2px solid black;
        //     padding: 10px 15px;
        //     margin: 20px 0px;
        //     background: none;
        //     font-size: 16px;
        //     &:hover{
        //         background: black;
        //         color: white
        //     }
        // }
    }
    
}



.third-section{
    text-align: center;
    margin: 70px 0px;
    &__title{
        font-size: 36px;
    }
    &__button{
        border: 2px solid rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 18px;
        background: none;
        margin-top: 40px;
        color: rgb(0, 0, 0);
        &:hover{
            background: black;
            color: rgb(255, 255, 255);
        }
    }
}

.fourth-section{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0px var(--container-margin);
    margin-top: 70px;
    &__left-block{
        width: calc(var(--width) / 2 - 50px);
        height: calc(var(--width) / 2);
        background: url('../images/mama-maria.jpg');
        // background-attachment: fixed;
        background-repeat:no-repeat;
        background-size: cover;
        position: relative;
        background-position: center;

        // &::before{
        //     content: '';
        //     position: absolute;
        //     width: calc(100% + 10px);
        //     height: 70px;
        //     background-color: rgb(255, 255, 255);
        //     top: -35px;
        //     left: -5px;
        //     transform: rotate(-3deg);
        // }
        // &::after{
        //     content: '';
        //     position: absolute;
        //     width: calc(100% + 10px);
        //     left: -5px;
        //     height: 70px;
        //     background-color: rgb(255, 255, 255);
        //     bottom: -35px;
        //     transform: rotate(3deg);
        // }
    }
    &__content{
        z-index: 10;
    }
    &__right-block{
        width: calc(var(--width) / 2 + 50px);
        height: max-content;
        padding: 0px 100px 0px 0px;
        
        position: relative;
        z-index: 1;
        font-size: 18px;
        h1{
            font-size: var(--title-font-size);
        }
        h3{
            font-size: var(--font-size);
        }
        // &::before{
        //     content: '';
        //     z-index: -1;
        //     position: absolute;
        //     width: 120px;
        //     height: 100%;
        //     background-color: rgb(255, 255, 255);
        //     left: -60px;
        //     top: 0;
        //     transform: rotate(9deg) scale(1.05);
        // }
    }
    
    padding-top: 0px;
    &__button{
        font-size: 16px;
        border: 2px solid black;
        padding: 10px 15px;
        margin: 5px 0px 25px 0px;
        background: none;
        &:hover{
            background: black;
            color: white
        }
    }
}

.fifth-section{
    width: var(--width);
    margin: 70px 0px;
    margin: 0px var(--container-margin);
    h1{
        font-size: var(--title-font-size);
    }
    p{
        white-space: pre-wrap;
        line-height: 1.7;
        font-size: var(--font-size);
    }
    h4{
        margin: 30px 0px 10px 0px;
        font-size: calc(var(--font-size) );
    }
    ul{
        li{
            margin-left: 35px;
            font-size: var(--font-size);
        }
    }
    button{
        border: 2px solid black;
        padding: 10px 15px;
        background: none;
        font-size: 16px;
        &:hover{
            background: black;
            color: white
        }
    }
}

@media screen and (max-width: 769px){
    .main{
        background-size: 100vw;
    }
    .third-section{
        margin: 10px 0px 60px 0px;
        &__title{
            font-size: 30px;
        }
        &__button{
            font-size: 17px;
            padding: 8px 13px ;
            margin-top: 30px;
        }
    }
    .first-section{
        position: relative;
        margin-top: 80px;
        width: calc(100vw - 40px);
        margin-left: 20px;
        height: calc(100vw + 20px);
        padding: 0;
        align-items: end;
        .background{
            
            height: 100%;
            background-image: url('../images/4.jpg');
            background-position-x: center;
            background-position-y: 0px;
            background-size: cover;
            filter: brightness(100%);
        }
        &__info{
            margin: 0px;
            display: block;
            h1{
                color: white;
                // text-shadow: 0px 0px 2px black;
                font-size: 30px;
                img{
                    display: none;
                }
                margin: 10px 0px 0px 0px;
            }
            h3{
                margin: 0px 0px 10px 0px;
                color: white;
                font-weight: 400;
            }
            button{
                border: 2px solid white;
                padding: 8px 13px;
                background: none;
                color: white;
                font-size: 16px;
                &:hover{
                    background: white;
                    color: black;
                }
            }
            background-color: rgba(255, 255, 255, 0.2);
            // backdrop-filter: blur(1px);
            padding: 0px 20px;
            padding-bottom: 20px;
        }
        &__info-left-block{
            width: calc(100%);
        }
        &__info-right-block{
          
             display: none !important;
        }
    }
    .fifth-section{
      
        width: 100vw !important;
        padding: 0px 20px !important;
      
        h4{
            margin-top: 10px;
        }
        p{
            font-size: 16px;
        }
    }
    .fourth-section{
        position: relative;
        display: block;
        height: max-content;
        margin-top: 60px;
        &__left-block{
            width: 100vw;
            background-size: 100vw;
            height: calc(100vw - 50px);
            top: 0px;
        }
        &__right-block{
            margin-top: -90px;
            width: 100%;
            height: 100%;
            padding: 0px;
            &::before{
                content: none;
                
            }
            button{
                margin: 0px 20px;
            }
            p{
                padding: 0px 20px;
            }
            h1, h3{
                
                padding: 0px 20px;
                
                color: rgb(0, 0, 0);
                text-shadow: 0px 0px 40px rgb(255, 255, 255);
            }
            h1{
                padding-top: 0px;
            }
            h3{
                margin-bottom: 0px;
                padding-bottom: 2px;
            }
            
        }
        &__content-title{
            div{
          
                height: max-content;
            }
            display: flex;
            align-items: center;
            height: 90px;
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(2px);
        }
    }

    .second-section{
        padding: 0px 20px;
        &__left-block{
            width: 100%;
        }
        &__right-block{
            display: none;
        }
    }
}