.poems{

    padding-top: 100px;
    background-attachment:scroll;
    &__filters{
       
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-top: 40px;
        width: var(--width);
        margin-left: calc((100vw - var(--width)) / 2 );
    }
    &__search{
        
        height: max-content;
        padding: 5px 10px;
        border: 1px solid #ccc;
        background-color: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        img{
            width: 15px;
        }
        input{
            max-width: 30vw;
            font-size: 16px;
            border: none;
            margin-left: 10px;
            color: #333;
            &:hover {
                border-color: #999;
            }
            &:focus {
                outline: none;
                border-color: #000000;
            }
        }
    }
    &__filter{
        font-size: var(--font-size);
        select {
            margin-left: 10px;
            padding: 5px  0px 5px 5px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 5px;
            background-color: #fff;
            color: #333;
            option{
                font-size: 16px;
            }
            &:hover {
                border-color: #999;
            }
            &:focus {
                outline: none;
                border-color: #000000;
            }
            
        }
        
    }

    .pagination{
        width: max-content;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 50px;
        button{
            padding: 5px;
            font-size: 16px;
            margin: 0px 5px;
            border-radius: 3px;
            border: 1px solid gray;
            &:disabled{
                
                border: 1px solid gray;
                border-radius: 3px;
                color: black;
                background-color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .poems{
        h1{
            font-size: 30px;
        }
        padding-top: 100px;
        background-repeat: repeat;
        // background-size: cover;
        background-position: center;
        // background-attachment:fixed;
        &__filters{
            padding: 0px 15px;
        }
        &__filter{
            
            select{
                margin: 0;
            }
        }
        .items-container{
            padding: 0px 15px;
            justify-content: space-between;
        }
    }
}