
.text-item{
    cursor: pointer;
    width: 320px;
    color: rgb(0, 0, 0);
    transition: 0.2s ease;
    &:hover{
        
            text-decoration: underline;
        
    }
    pre{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 4; /* Ограничиваем до двух строк */
    }
    h2{
        font-size: calc(var(--font-size) + 4px);
    }
}